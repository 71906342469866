import { Environments } from '../../interfaces/general/environment-variables';

/**
 * Method that returns the environment based on the URL
 * @param envs
 */
const getEnvByUrl = (envs: string[]): string => {
  if (typeof window === 'undefined') return '';

  return envs.find((env) => window.origin.includes(env)) ?? 'production';
};

/**
 * Method that returns the backend URL based on the environment
 */
export const getBackendUrl = (route: string = '') => {
  // getEnvByUrl() is just a safe guard in case the currentEnvironment is not set
  const env = process.env.currentEnvironment ?? getEnvByUrl(Object.values(Environments));

  switch (env) {
    case Environments.LOCAL: {
      return `http://localhost:3000/api/${route}`;
    }
    case Environments.DEV: {
      return `https://dev-api.vfq.emergn.com/api/${route}`;
    }
    case Environments.QA: {
      return `https://qa-api.vfq.emergn.com/api/${route}`;
    }
    case Environments.STAGE: {
      return `https://stage-api.vfq.emergn.com/api/${route}`;
    }
    case Environments.POC: {
      return `https://poc-api.valueflowquality.net/api/${route}`;
    }
    default: {
      return `https://api.vfq.emergn.com/api/${route}`;
    }
  }
};

/**
 * Method that returns the frontend URL based on the environment
 * @param route - The route path to append to the base URL
 * @returns The complete frontend URL for the given environment and route
 */
export const getBaseUrl = (route: string = '') => {
  switch (process.env.currentEnvironment) {
    case Environments.LOCAL: {
      return `http://localhost:3005/app/${route}`;
    }
    case Environments.DEV: {
      return `https://dev.vfq.emergn.com/app/${route}`;
    }
    case Environments.QA: {
      return `https://qa.vfq.emergn.com/app/${route}`;
    }
    case Environments.STAGE: {
      return `https://stage.vfq.emergn.com/app/${route}`;
    }
    default: {
      return `https://praxis.emergn.com/app/${route}`;
    }
  }
};

/**
 * Generates the complete registration URL for a user signup link
 * @param userLink - The unique registration link identifier for the user
 * @returns The complete frontend URL for user registration with the signup link appended
 */
export const getRegistrationURL = (userLink: string) => {
  return getBaseUrl(`sign-up/${userLink}`);
};
